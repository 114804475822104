import React from 'react';
import banner from '../../assets/img/banner.png';
import Filters from './Filters';
import HeaderLogo from './HeaderLogo';

const HeaderComponent: React.FC = () => {
    return (
        <div className='header'>
            <HeaderLogo/>
            <div className='header__banner-content'>
                <div className='banner-text'>
                    <h2>Работа найдётся для каждого</h2>
                    <p>Бесплатный сервис подбора вакансий без опыта работы</p>
                </div>
                <Filters/>
            </div>
            <div className='header__img'>
                <img src={banner} alt='banner'/>
            </div>

        </div>
    )
}

export default HeaderComponent;