import React from 'react';
import { useAppDispatch } from '../../../app/hooks';
import { setAnswer4 } from '../../../app/QuestionFormSlice';
import { Checkbox } from 'antd';
import type { CheckboxValueType } from 'antd/es/checkbox/Group';

const Page4: React.FC = () => {
    
    const dispatch = useAppDispatch();

    const onChange = (checkedValues: CheckboxValueType[]) => {
        //console.log('checked = ', checkedValues);
        dispatch(setAnswer4(checkedValues.map(item => item.toString())));
      };

    return (
        <div className='page'>
            <h6>Какая работа Вам интересна?</h6>
                <Checkbox.Group style={{ width: '100%' }} onChange={onChange}>
                    <div className='page5-checkbox-wrapper'>
                        <Checkbox value={'Работа из дома'} className='page-value-text'>Хочу работать удаленно</Checkbox>
                        <Checkbox value={'Работа на складе'} className='page-value-text'>Хочу работать на складе / в магазине</Checkbox>
                        <Checkbox value={'Курьер на авто'} className='page-value-text'>Хочу работать на авто</Checkbox>
                        <Checkbox value={'Пеший курьер'} className='page-value-text'>Хочу работать пешим курьером / на велосипеде / на самокате</Checkbox>
                    </div>
                </Checkbox.Group>

            
        </div>
    )
}

export default Page4;