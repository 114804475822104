import React, { useState, useEffect } from 'react'
import { Select } from 'antd';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { setAnswer2 } from '../../../app/QuestionFormSlice';
import { getCityToOptions } from '../../../helpers/functions';

const Page2: React.FC = () => {
    const [geo, setGeo] = useState<{ value: string; label: string; }[]>([]);
    const stateOffers = useAppSelector(state => state.offers.offers); // массив офферов
    const dispatch = useAppDispatch();

    const handleSelect = (value: string) => { // 
        dispatch(setAnswer2(value))
    };

    useEffect(() => {
        const optionsCity = getCityToOptions(stateOffers);
        setGeo(optionsCity);
    }, [stateOffers])
    
    return (
        <div className='page'>
            <h6>В каком городе Вы ищете работу?</h6>
            <div className='page-input-container page-container'>
                <Select 
                    showSearch
                    showArrow
                    allowClear
                    bordered={false} 
                    listHeight={172}
                    placeholder='Введите город' 
                    className='page-value-text page-select' 
                    id='page3-input'
                    onChange={handleSelect}
                    filterOption={(input, option) =>
                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                      }
                    options={geo}
                />
            </div>

        </div>
    )
}

export default Page2;