import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "./store";
import { QuestionsState } from '../assets/types/types';


const initialState: QuestionsState = {
    questionFormFilled: false,
    questions: {
        answer1: '',
        answer2: '',
        answer3: 0,
        answer4: [],
    },
}


export const questionSlice = createSlice({
    name: 'questions',
    initialState: initialState,
    reducers: {
        setAnswer1: (state, action: PayloadAction<string>) => {
            state.questions.answer1 = action.payload;
        },
        setAnswer2: (state, action: PayloadAction<string>) => {
            state.questions.answer2 = action.payload;
        },
        setAnswer3: (state, action: PayloadAction<number>) => {
            state.questions.answer3 = action.payload;
        },
        setAnswer4: (state, action: PayloadAction<string[]>) => {
            state.questions.answer4 = action.payload;            
        },
        setQuestionComleted: (state, action: PayloadAction<boolean>) => {
            state.questionFormFilled = action.payload;
            //localStorage.setItem('stateTJ-question-form', JSON.stringify(state));
        },
        
    }
})

export const {  
    setAnswer1,
    setAnswer2,
    setAnswer3,
    setAnswer4,
    setQuestionComleted,
 } = questionSlice.actions;

export const selectQuestionState = (state: RootState) => state.questions;

export default questionSlice.reducer;