import React from 'react';
import { Form, Input } from 'antd';


interface ChildProps {
    setLastName: React.Dispatch<React.SetStateAction<string>>;
}

const LastName: React.FC<ChildProps> = ( {setLastName}) => {

    return (
        <Form.Item
                        name="last-name"
                        label='Фамилия'
                        rules={[{ required: true, message: 'Поле обязательно для заполнения' }]}>
                        <Input 
                            type='text'
                            placeholder="Фамилия" 
                            className='signup_input'
                            prefix={<label className="floating_label disabled" htmlFor="last-name" id="label_last-name">Фамилия</label>}
                            onChange={(e) => {
                                const label = document.getElementById('label_last-name') as HTMLElement;
                                if (e.target.value !== '') {
                                    label.classList.remove('disabled');
                                    setLastName(e.target.value);
                                } else {
                                    label.classList.add('disabled');
                                    setLastName('');
                                }
                            }}
                            />
                    </Form.Item>
    )
}

export default LastName;