import React from 'react';
import HeaderComponent from '../header/Header';
import CardBlockWrapper from './cards/CardBlockWrapper';
import { Spin } from 'antd';
import { useAppSelector } from '../../app/hooks';
// import QuestionForm from './questionForm/QuestionForm';

interface mainProps {
    load: boolean;
  }

const MainPage: React.FC<mainProps> = ({load}) => {

    const offerState = useAppSelector(state => state.offers.offers);

    return (
        <div className='main-wrapper'>
            <HeaderComponent/>
            {load && offerState ? 
                <div className="loading_screen">
                    <Spin size="large" />
                </div>
                :
                <CardBlockWrapper/>
            }
        </div>
    )
}

export default MainPage;