import { EntriesType, GeoType, NewGeoType, OfferCabinetType, OfferCategory, offerState } from "../assets/types/types";
import car from '../assets/icons/car.svg';
import bag from '../assets/icons/school_satchel.svg';
import baby from '../assets/icons/baby.svg';
import house from '../assets/icons/house.svg';
import hand from '../assets/icons/muscle.svg';
import calendar from '../assets/icons/spiral_calendar_pad.svg';

export function getOfferWithNewGeo(arrayOffer: EntriesType[], geo: NewGeoType[]) {
    const offersNewGeo = arrayOffer.map((offer) => {
        const objectGeo = geo.filter(objgeo => objgeo.id === offer.geo[0].name);
        if (objectGeo.length) {
            const geoArray = objectGeo[0].geo;
            offer.geoID = objectGeo[0].id;
            const geoTypeArray: GeoType[] = geoArray.map((item) => {
                return {
                    name: item[0],
                    parent: null,
                    _mby: '',
                    _by: '',
                    _modified: 1,
                    _created: 1,
                    _id: '',
                    coordinates: '',
                    _link: ''
                }
            })
            offer.geo = geoTypeArray;
            return offer
        } else {
            return offer
        }
    })
    return offersNewGeo
}

export function isOfferCabinetType(data: any): data is OfferCabinetType { //функция для проверки типа ответа по запросу в кабинет
    if (!data || typeof data !== 'object') {
      return false;
    }
    const { offers } = data;
    if (!Array.isArray(offers)) {
      return false;
    }
    for (const offer of offers) {
      if (!offer || typeof offer !== 'object' || typeof offer.id !== 'string' || typeof offer.platform_id !== 'string') {
        return false;
      }
    }
    return true;
}

export function getPositionToOptions(stateOffers: EntriesType[]) {
    const positionsSet = new Set(stateOffers.map((item) => item.name)); //достаем из массива все должности и создаем сет с уникальными значениями
    const positions = Array.from(positionsSet);  // преобразуем сет в массив
    const optionsPosition = positions.map((item, index) => {  //создаем массив объектов вида {value: string, label: string}
      return {value: item, label: item}
    })
    return optionsPosition
}

export function getCityToOptions(stateOffers: EntriesType[]) {
    const sityAr = stateOffers.map((item) => item.geo) // создаем массив, состоящий из массива с объектами городов
    const sityArr2 = sityAr.map((geoArray) => { // достаем названия городов
      if (geoArray.length > 1) {
        return geoArray.map(item => item.name)
      } else {
        return geoArray[0].name
      }
    });
    const sitySet = new Set(sityArr2.flat()); // коллекция уникальных значений
    const sityUnique = Array.from(sitySet); // массив уникальных значений
    const sitySort = sityUnique.sort();
    const optionsCity = sitySort.map((item) => {   //создаем массив объектов вида {value: string, label: string}
      return {value: item, label: item}
    })
    // const Russian = optionsCity.splice(optionsCity.findIndex(a => a.value === 'Вся Россия'), 1) || [{value: 'Вся Россия', label: 'Вся Россия'}];
    //const distValue = [{value: 'Удаленно', label: 'Удаленно'}];
    //console.log(Russian)
    const Moscow = optionsCity.splice(optionsCity.findIndex(a => a.value === 'Москва'), 1);
    const SPB = optionsCity.splice(optionsCity.findIndex(a => a.value === 'Санкт-Петербург'), 1);
    const dist = optionsCity.splice(optionsCity.findIndex(a => a.value === 'Удаленно'), 1);
    const citiesFirst = Moscow.concat(SPB, dist);
    //optionsCity.splice(0, 0, Russian);
    const newOptionsCity = citiesFirst.concat(optionsCity);
    return newOptionsCity
}

export function showGeo(props: EntriesType) {
    if (props.geo.length > 10) {
        if ((props.geo.filter(elem => elem.name === 'Москва')).length && (props.geo.filter(elem => elem.name === 'Санкт-Петербург')).length) {
            return 'Москва, Санкт-Петербург, регионы'
        } else {
            if ((props.geo.filter(elem => elem.name === 'Москва')).length) {
                return 'Москва, регионы'
            } else {
                if ((props.geo.filter(elem => elem.name === 'Санкт-Петербург')).length) {
                    return 'Санкт-Петербург, регионы'
                } else {
                    return 'Регионы'
                }
            }
        }
    
    } else {
        return props.geo.map(x => x.name).join(', ')
    }
}

export const offersCategories = (offers: OfferCategory) => [{
        title: 'Курьер на авто',
        img: car,
        offers: offers.categories.courier
    },{
        title: 'Подработка',
        img: bag,
        offers: offers.categories.partTime
    },
    {
        title: 'Без опыта работы',
        img: baby,
        offers: offers.categories.noExper
    },
    {
        title: 'Работа из дома',
        img: house, 
        offers: offers.categories.home
    },
    {
        title: 'Работа на складе',
        img: hand,
        offers: offers.categories.stock
    },
    {
        title: 'Вахта',
        img: calendar,
        offers: offers.categories.watch
    },
    {
        title: 'Другие вакансии',
        img: '',
        offers: offers.categories.other
    }
]

export function getOfferLink(props: EntriesType, browserParams: URLSearchParams) {
    if (props.refid) {
        return props.refid
    } else {
        const genericOfferLink = new URL("https://pxl.leads.su/aff_c")
        browserParams.forEach((value, key) => {
            genericOfferLink.searchParams.set(key, value)
        })
        genericOfferLink.searchParams.set('aff_sub5', 'top-job');
        genericOfferLink.searchParams.set('sys_sub4', 'top-job');
        if (props.leads_id) {
            genericOfferLink.searchParams.set("offer_id", props.leads_id);
        }
        if (props.platform_id) {
            genericOfferLink.searchParams.set("pltfm_id", props.platform_id);
        }
        return genericOfferLink.toString()
    }
}


// export function findOtherJob(allOffers: EntriesType[], offer: EntriesType) {
//     const otherJobsByName = allOffers.filter(
//       (job) => job.name === offer.name && (job.id !== offer.id || job.ID !== offer.ID || job.leads_id !== offer.leads_id)
//     );
//     const otherJobsByTags = allOffers.filter(
//       (job) =>
//         (job.id !== offer.id || job.ID !== offer.ID || job.leads_id !== offer.leads_id) &&
//         job.tags?.some((tag) =>
//           offer?.tags?.some((offerTag) => offerTag.name === tag.name)
//         )
//     );
  
//     let selectedOtherJob = [];
  
//     if (otherJobsByName.length > 0) {
//       selectedOtherJob = otherJobsByName.length > 4 ? otherJobsByName.slice(0, 4) : otherJobsByName;
//     } else {
//       selectedOtherJob = otherJobsByTags.length > 4 ? otherJobsByTags.slice(0, 4) : otherJobsByTags;
//     }
  
//     console.log('select', otherJobsByName, otherJobsByTags);
//     return selectedOtherJob;
//   }
  
  

export function findOtherJob(allOffers: EntriesType[], offer: EntriesType) {
    const otherJobsByName = allOffers.filter(
      (job) =>
        job.name === offer.name &&
        (job.id !== offer.id || job.ID !== offer.ID || job.leads_id !== offer.leads_id)
    );
    const otherJobsByTags = allOffers.filter(
      (job) =>
        (job.id !== offer.id || job.ID !== offer.ID || job.leads_id !== offer.leads_id) &&
        job.tags?.some((tag) =>
          offer?.tags?.some((offerTag) => offerTag.name === tag.name)
        )
    );
  
    let selectedOtherJob: EntriesType[] = [];
  
    if (otherJobsByName.length >= 4) {
        selectedOtherJob = otherJobsByName.slice(0, 4);
      } else {
        selectedOtherJob = otherJobsByName.concat(otherJobsByTags).slice(0, 4);
      }
  
    //console.log('select', selectedOtherJob);
    return selectedOtherJob;
  }
  
export function getStateLS() {
    const stateLS = localStorage.getItem('stateTJ');
    const initialState: offerState = {
        offers: [],
        stateOffers: [],
        // filters: {
        //     position: [],
        //     city: [],
        //     filterTags: {
        //         minSalary: 0,
        //         tags: []
        //     },
        // },
        offersCat: {
            categories: {
                courier: [],
                partTime: [],
                noExper: [],
                home: [],
                stock: [],
                watch: [],
                other: []
            },
        },
        modal_response: {
            show_modal: false,
            offer: null
        }
    }
    
    if (stateLS) {
        const state: offerState = JSON.parse(stateLS);
        return state
    } else return initialState
  }