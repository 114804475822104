import React from 'react';
import { Form, Input } from 'antd';


interface ChildProps {
    setFirstName: React.Dispatch<React.SetStateAction<string>>;
}

const FirstName: React.FC<ChildProps> = ( {setFirstName}) => {

    return (
        <Form.Item
                        name="first-name"
                        label='Имя'
                        rules={[{ required: true, message: 'Поле обязательно для заполнения' }]}>
                        <Input 
                            type='text'
                            placeholder="Имя" 
                            className='signup_input'
                            prefix={<label className="floating_label disabled" htmlFor="name" id="label_first-name">Имя</label>}
                            onChange={(e) => {
                                const label = document.getElementById('label_first-name') as HTMLElement;
                                if (e.target.value !== '') {
                                    label.classList.remove('disabled');
                                    setFirstName(e.target.value);
                                } else {
                                    label.classList.add('disabled');
                                    setFirstName('');
                                }
                            }}
                            />
                    </Form.Item>
    )
}

export default FirstName;