import { DBOfferCount, EntriesType, OfferAff, OfferCategory, QuestionsState } from "../assets/types/types";

export function filter(
    offers: (EntriesType | OfferAff)[],
    filters: {
      position: string[];
      city: string[];
      filterTags: {
        minSalary: number;
        tags: string[];
      };
    }
  ): (EntriesType | OfferAff)[] {
    const { position, city, filterTags } = filters;
    const { minSalary, tags } = filterTags;
     
    let filteredOffers = offers;
  
    if (position.length) {
      filteredOffers = filteredOffers.filter((offer) =>
        position.some((pos) => offer.name.toLowerCase().includes(pos.toLowerCase()))
      );
    }
  
    if (city.length) {
      filteredOffers = filteredOffers.filter((offer) =>
        city.some((cityItem) =>
          (cityItem === 'Вся Россия' || cityItem === 'РФ') ?
          true :
          offer.geo.some((geo) =>
            [cityItem, 'РФ', 'Вся Россия', 'Удаленно'].includes(geo.name)
          )
        )
      );
    }
  
    if (minSalary) {
        filteredOffers = filteredOffers.filter((offer) => {
          const parsedSalary = parseWageDescription(offer.wage_description);
          const beginStr = offer.wage_description.substring(0, 2).toLowerCase();
          if (beginStr === 'от' && parsedSalary !== null && parsedSalary <= minSalary ) {
            return true
          } else {
            return parsedSalary !== null && parsedSalary >= minSalary;
          }
          // const parsedSalary = parseWageDescription(offer.wage_description);
          // return parsedSalary !== null && parsedSalary >= minSalary;

        });
    }

    if (tags.length) {
        filteredOffers = filteredOffers.filter((offer) =>
          tags.every((tag) => {
            if (offer.tags === null) {
              return false;
            }
    
            if (tag.toLowerCase() === 'удаленка') {
              return offer.tags.some(
                (offerTag) =>
                  offerTag.name.toLowerCase() === 'удаленка' ||
                  offerTag.name.toLowerCase() === 'удаленно' ||
                  offerTag.name.toLowerCase() === 'дистанционно' ||
                  offerTag.name.toLowerCase() === 'работа из дома'
              );
            }
    
            return offer.tags.some((offerTag) =>
              offerTag.name.toLowerCase().includes(tag.toLowerCase())
            );
          })
        );
      }

    return filteredOffers;
  }
  
function parseWageDescription(wageDescription: string | null | undefined): number | null {
    const salary = wageDescription!.replace(/\D/g, '');
    return salary ? Number(salary) : null;
}

export function sortByCategories(offers: EntriesType[]) {

    const offerCategory: OfferCategory = {
        categories: {
          courier: [],
          partTime: [],
          noExper: [],
          home: [],
          stock: [],
          watch: [],
          other: []
        }
    };

    offers.forEach((item: EntriesType) => {
        if (item.tags) {
        let addedToCategory = false;

            item.tags.forEach((tag) => {
            const tagName = tag.name;
        
            if (tagName === "Курьер на авто") {
                offerCategory.categories.courier.push(item);
                addedToCategory = true;
            }
            if ((tagName === "Частичная занятость") || (tagName === "Подработка")) {
                offerCategory.categories.partTime.push(item);
                addedToCategory = true;
            }
            if (tagName === "Без опыта работы") {
                offerCategory.categories.noExper.push(item);
                addedToCategory = true;
            }
            if ((tagName === "Работа из дома") || (tagName === "Дистанционно") || (tagName === "Удаленно")) {
                offerCategory.categories.home.push(item);
                addedToCategory = true;
            }
            if ((tagName === "Склад") || (tagName === "Работа на складе")) {
                offerCategory.categories.stock.push(item);
                addedToCategory = true;
            }
            if (tagName === "Вахта") {
                offerCategory.categories.watch.push(item);
                addedToCategory = true;
            }
            });
            if (!addedToCategory) {
                offerCategory.categories.other.push(item)
            }
        }
        });
    return offerCategory;
}

export function sortOffersByClick(offers: EntriesType[], clickData: DBOfferCount) {
  const sortedOffers = offers.slice().sort((a, b) => {
    
    const idA = a.geoID || a.id || a.leads_id || a.ID;
    const idB = b.geoID || b.id || b.leads_id || b.ID;
   
    const clicksA = clickData[idA]?.counterClick || 0;
    const clicksB = clickData[idB]?.counterClick || 0;
    
    return clicksB - clicksA;
  });
  
  return sortedOffers;
}

export function filterCitizenship(offers: EntriesType[], citizenship: string) {
  const normalizedCitizenship = citizenship.toLowerCase();
  const filteredOffers = offers.filter((offer) => {
    const citizenships = offer.citizenship?.map((country) => country.name.toLowerCase());

    if (citizenships?.includes(normalizedCitizenship)) {
      return true;
    }

    // Если citizenship не равно "казахстан" или "беларусь", ищем "СНГ"
    if (normalizedCitizenship !== 'казахстан' && normalizedCitizenship !== 'беларусь' && normalizedCitizenship !== 'россия') {
      return citizenships?.includes('снг');
    }

    return false;
  });

  return filteredOffers;
}


export function filterCity(offers: EntriesType[], city: string) {
  if (city === 'Удаленно') {
    const filteredOffers = offers.filter((offer) => offer.tags?.some((offerTag) => offerTag.name === 'Работа из дома'))
    return filteredOffers;
  }

  const filteredOffers = offers.filter((offer) => 
    offer.geo.some((geo) => geo.name === city)
    )
  return filteredOffers
}

export function filterAge(offers: EntriesType[], age: number) {
  const filteredOffers = offers.filter((offer) => Number(offer.age_from) <= age && Number(offer.age_to) >= age);
  return filteredOffers;
}

export function filterTags(offers: EntriesType[], tags: string[]) { 
  const filteredOffers = offers.filter((offer) =>
    tags.some((tag) => {
      if (offer.tags === null) {
        return false;
      }

      // if (tag.toLowerCase() === 'работа из дома') {
      //   return offer.tags.some(
      //     (offerTag) =>
      //       offerTag.name.toLowerCase() === 'удаленка' ||
      //       offerTag.name.toLowerCase() === 'удаленно' ||
      //       offerTag.name.toLowerCase() === 'дистанционно' ||
      //       offerTag.name.toLowerCase() === 'работа из дома'
      //   );
      // }

      return offer.tags.some((offerTag) =>
        offerTag.name.toLowerCase().includes(tag.toLowerCase())
      );
    })
  );
  return filteredOffers;
}

export function filterByQuestionForm(offers: EntriesType[], answers: QuestionsState) {
  let filteredOffers = offers;

  if (answers.questions.answer1) {
    filteredOffers = filterCitizenship(filteredOffers, answers.questions.answer1)
  }

  if (answers.questions.answer2) {
    filteredOffers = filterCity(filteredOffers, answers.questions.answer2)
  }

  if (answers.questions.answer3) {
    filteredOffers = filterAge(filteredOffers, answers.questions.answer3)
  }

  if (answers.questions.answer4.length) {
    filteredOffers = filterTags(filteredOffers, answers.questions.answer4)
  }

  return filteredOffers

}