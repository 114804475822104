import React, { useEffect, useState } from 'react';
import Page1 from './Page1';
import { Progress } from 'antd';
import Page2 from './Page2';
import Page3 from './Page3';
import Page4 from './Page4';
import SvgArrow from './SvgArrow';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { setQuestionComleted } from '../../../app/QuestionFormSlice';
import { filterByQuestionForm } from '../../../helpers/filterFunctions';
import { setFilterOffers } from '../../../app/OfferSlice';
import { ref, set } from "firebase/database";
import { db } from "../../../Firebase";

interface ChildProps {
    setShowQuestionForm: React.Dispatch<React.SetStateAction<boolean>>;
}

const QuestionForm: React.FC<ChildProps> = ( {setShowQuestionForm} ) => {
    const authState = useAppSelector(state => state.auth);
    const [currentPage, setCurrentPage] = useState(1);
    const dispath = useAppDispatch();
    const offers = useAppSelector(state => state.offers.offers);
    const answers = useAppSelector(state => state.questions);

    const handleNavigLeft = () => {
        if (currentPage !== 1) {
            setCurrentPage(currentPage - 1);
        }
    }

    const handleNavigRight = () => {
        setCurrentPage(currentPage + 1);
    }

    const handleClose = () => {
        setShowQuestionForm(false);
        localStorage.setItem('tj-question-form', 'close');
        set(ref(db, 'user-answers/' + authState.user.firebase_id), {
            userTJCookieId: authState.user.cookie_id,
            answers: 'close'
          });

    }

    const handleSubmit = (e: React.SyntheticEvent) => {
        e.preventDefault();
        dispath(setQuestionComleted(true));
        setShowQuestionForm(false);

        const filteredOffers = filterByQuestionForm(offers, answers);
        dispath(setFilterOffers(filteredOffers));

        localStorage.setItem('tj-question-form', JSON.stringify(answers));

        set(ref(db, 'user-answers/' + authState.user.firebase_id), {
            userTJCookieId: authState.user.cookie_id,
            answers: answers.questions
          });
    }

    useEffect(() => {
        const btnLeft = document.getElementById('btn-navig-left') as HTMLElement;
        if (currentPage === 1) {
            btnLeft.classList.add('btn-disabled')
        } else {
            btnLeft.classList.remove('btn-disabled');
        }
    })


    return (
        <form className='question-form' method="post" onSubmit={handleSubmit}>
            <div className='question-form__close-btn' onClick={handleClose}>
                    X
            </div>
            <div className='question-form__content'>
                <div className='question-form__header'>
                    <div className='question-form__title'>
                        Анкета
                    </div>
                    <div className='question-form__desc'>
                        Ответьте на несколько вопросов и мы подберем работу именно для вас.
                    </div>
                </div>
                <div className='question-form__questions'>
                    {currentPage === 1 ? <Page1/> :
                        currentPage === 2 ? <Page2/> :
                            currentPage === 3 ? <Page3/> :
                                currentPage === 4 ? <Page4/> : <></>}
                </div>
                <div className='question-form__footer'>
                    <div className='navig-btn btn-disabled' id='btn-navig-left' onClick={handleNavigLeft}>
                        <svg width="15" height="13" viewBox="0 0 15 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M14.9688 7C14.9688 7.5625 14.5312 8 14 8H4.40625L7.6875 11.3125C8.09375 11.6875 8.09375 12.3438 7.6875 12.7188C7.5 12.9062 7.25 13 7 13C6.71875 13 6.46875 12.9062 6.28125 12.7188L1.28125 7.71875C0.875 7.34375 0.875 6.6875 1.28125 6.3125L6.28125 1.3125C6.65625 0.90625 7.3125 0.90625 7.6875 1.3125C8.09375 1.6875 8.09375 2.34375 7.6875 2.71875L4.40625 6H14C14.5312 6 14.9688 6.46875 14.9688 7Z" fill="#117FFF"/>
                        </svg>
                        <span>Назад</span>
                    </div>
                    <div id='progress-bar'>
                        <Progress percent={currentPage*100/4} showInfo={false}/>
                    </div>
                    {currentPage === 4 ? 
                        <button type='submit' className='navig-btn' id='btn-form-submit'>
                            <span>Готово</span>
                            <SvgArrow/>
                        </button>
                    :
                        <div className='navig-btn' id='btn-navig-right' onClick={handleNavigRight}>
                            <span>Вперед</span>
                            <SvgArrow/>
                        </div>
                    }
                    {/* <div className='navig-btn' id='btn-navig-right' onClick={handleNavigRight}>
                        {currentPage === 4 ? <span>Готово</span> : <span>Вперед</span>}
                        <SvgArrow/>
                    </div> */}
                    <div className='question-form__pagination'>{currentPage}/4</div>
                </div>
            </div>

        </form>
    )
}

export default QuestionForm;