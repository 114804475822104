import React, { useState } from 'react';
import type { RadioChangeEvent } from 'antd';
import { Input, Radio, Space } from 'antd';
import { useAppDispatch } from '../../../app/hooks';
import { setAnswer1 } from '../../../app/QuestionFormSlice';

const Page1: React.FC = () => {
    const [radioValue, setRadioValue] = useState<string>('');
    const [otherValue, setOtherValue] = useState<string>('');
    const dispatch = useAppDispatch();

    const handleRadio = (e: RadioChangeEvent) => {
        if (e.target.value === 'other') {
            setRadioValue(e.target.value);
            dispatch(setAnswer1(otherValue));
        } else {
            setRadioValue(e.target.value);
            dispatch(setAnswer1(e.target.value));
        }
    };

    return (
        <div className='page'>
            <h6>Гражданство</h6>
            <Radio.Group onChange={handleRadio} value={radioValue} buttonStyle='solid' style={{width: '100%'}}>
                <Space direction='vertical' className='page1-radio-wrapper'>
                    <Radio value={'Россия'}>
                        <span className='page-value-text'>
                            Россия
                        </span>
                    </Radio>
                    <Radio value={'Беларусь'}>
                        <span className='page-value-text'>
                            Беларусь
                        </span>
                    </Radio>
                    <Radio value={'Казахстан'}>
                        <span className='page-value-text'>
                            Казахстан
                        </span>
                    </Radio>
                    <Radio value={'other'} style={{width: '100%'}}>
                        <div className='page-input-container'>
                            <Input 
                                bordered={false} 
                                placeholder='Другое' 
                                className='page-value-text page-input' 
                                id='page1-other'
                                onChange={(e) => {
                                    setRadioValue('other');
                                    setOtherValue(e.target.value);
                                    dispatch(setAnswer1(e.target.value));
                                }}
                            />
                        </div>
                    </Radio>
                </Space>
                
            </Radio.Group>

        </div>
    )
}

export default Page1;