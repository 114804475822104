import React from 'react';
import ResponseForm from './ResponseForm';
import { EntriesType } from '../../../assets/types/types';

const WrapperSignUp: React.FC<EntriesType> = (props) => {
    return (
        <div className='wrapper_signup'>
            <ResponseForm {...props}/>
        </div>
    )
}

export default WrapperSignUp;