// import firebase from 'firebase/app';
import 'firebase/auth';
import { getAuth } from 'firebase/auth';
//import { getFirestore } from "firebase/firestore";
import { getDatabase } from 'firebase/database';
// Import the functions you need from the SDKs you need

import { initializeApp } from "firebase/app";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
export const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL
};

const firebaseApp = initializeApp(firebaseConfig);
//const app = initializeApp(firebaseConfig);

const auth = getAuth(firebaseApp); // For Authentication
//const db = getFirestore(firebaseApp); // For Using Database
const db = getDatabase();

export { auth, db };