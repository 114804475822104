import React from 'react'
import { Input } from 'antd';
import { useAppDispatch } from '../../../app/hooks';
import { setAnswer3 } from '../../../app/QuestionFormSlice';
//import { setAnswer4 } from '../../../app/OfferSlice';


const Page3: React.FC = () => {
    const dispatch = useAppDispatch();

    // const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    //     const { value: inputValue } = e.target;
    //     // const reg = /^\d+$/;
    //     // if (reg.test(inputValue) || inputValue === '' || inputValue === '-') {
    //     //     // onChange(inputValue);
    //     // }
    //   };
    
    return (
        <div className='page'>
            <h6>Сколько Вам лет?</h6>
            <div className='page-input-container page-container'>
                <Input 
                    bordered={false} 
                    placeholder='Введите число' 
                    className='page-value-text page-input' 
                    id='page2-input'
                    // onChange={handleChange}
                    type='number'
                    onChange={(e) => {
                        // console.log('page4:', e.target.value);
                        dispatch(setAnswer3(Number(e.target.value)));
                    }}
                />
            </div>

        </div>
    )
}

export default Page3;