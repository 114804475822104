import React from 'react';
import { Form, Select } from 'antd';


interface ChildProps {
    setGender: React.Dispatch<React.SetStateAction<string>>;
}

const Gender: React.FC<ChildProps> = ( {setGender}) => {

    return (
        <Form.Item
            name="gender"
            label='Пол'
            rules={[{ required: true, message: 'Поле обязательно для заполнения' }]}>
            <Select 
                placeholder="Пол" 
                className='signup_select signup_select_gender'
                onChange={(value) => {setGender(value)}}
            >
                <Select.Option value="Мужской">Мужской</Select.Option>
                <Select.Option value="Женский">Женский</Select.Option>
            </Select>
        </Form.Item>
    )
}

export default Gender;