import React from 'react';
import { Form, Input, Row, Col } from 'antd';


interface ChildProps {
    setEmail: React.Dispatch<React.SetStateAction<string>>;
    error: string | undefined
}


const Email:  React.FC<ChildProps> = ( {setEmail, error}) => {


    return (
        <Form.Item 
            name="email"
            label="Ваш Email"
            rules={[{
                type: 'email',
                message: 'Неверный формат email',
              },{ 
                required: true, 
                message: 'Поле обязательно для заполнения' }
            ]}
            validateStatus={error ? "error" : undefined}
            help={error}
            >
            <Row gutter={[24, 16]}>
                <Col flex='auto'>
                    <Input
                        placeholder="Ваш Email" 
                        // type='email' 
                        autoComplete='disabled'
                        className='signup_input'
                        prefix={<label className="floating_label disabled" htmlFor="email" id="label_email">Ваш Email</label>}
                        onChange={(e) => {
                            const label = document.getElementById('label_email') as HTMLElement;
                            if (e.target.value !== '') {
                                label.classList.remove('disabled');
                                setEmail(e.target.value);
                            } else label.classList.add('disabled')
                        }}
                    />
                    {/* <Button 
                        type='link' 
                        className='button_re_send_code disabled' 
                        id='button_re_send'
                    >
                        Выслать код повторно
                    </Button> */}
                </Col>
                {/* <Col flex='210px'>
                    {buttonSendCode ? 
                    <Button 
                        block 
                        className='signup_button button_code'
                        //onClick={handleSendCode}
                    >
                        Выслать код
                    </Button>
                    :
                    <Input
                        placeholder='Введите код'
                        className='signup_input'
                        prefix={<label className="floating_label disabled" htmlFor="send_code" id="label_send_code">Код подтверждения</label>}
                        onChange={(e) => {
                            const label = document.getElementById('label_send_code') as HTMLElement;
                            if (e.target.value !== '') {
                                label.classList.remove('disabled');
                            } else label.classList.add('disabled')
                        }}
                    />
                    }

            </Col> */}
        </Row>   
    </Form.Item>
    )
}

export default Email;