import React, { useState } from 'react';
//import car from '../../../assets/icons/car.svg';
import Card from './Card';
import { EntriesType } from '../../../assets/types/types';
import arrow_down from '../../../assets/icons/arrow-down.svg';
import arrow_up from '../../../assets/icons/arrow-up.svg';
//import { useAppSelector } from '../../../app/hooks';
//import { sortOffersByClick } from '../../../helpers/filterFunctions';

const CardBlock: React.FC<{title: string, img: string, offers: EntriesType[]}> = (props) => {
    //const clicks = useAppSelector(state => state.filters.offersClick);
    const cardsArray = props.offers;
    //const sortOffers = clicks ? sortOffersByClick(cardsArray, clicks) : cardsArray;
    const sortOffers = cardsArray;
    const [showAll, setShowAll] = useState(false);
    const displayedCards = showAll ? sortOffers : sortOffers.slice(0, 3);

    const handleShowAll = () => {
        setShowAll(!showAll);
      };


    function isShow (cardsArray: EntriesType[]) {
        if (cardsArray.length > 3) {
            return true
        } else return false
    }
    
    return (
        <div className='card-block'>
            <div className='card-block__title'>
                <h2>{props.title}</h2>
                {props.img ? <img src={props.img} alt='car-icon'/> : '' }
            </div>
            <div className='card-block__list'>
                {displayedCards.map((item, index) => (
                    <div key={index}>
                        <Card {...item}/>
                    </div>
                ))}
            </div>
            {isShow(cardsArray) ? 
            <div>
                {!showAll ? 
                <div className='button button-show-all' onClick={handleShowAll}>
                    Показать все
                    <img src={arrow_down} alt='arrow'/>
                </div>
                :
                <div className='button button-show-all' onClick={handleShowAll}>
                    Свернуть список
                    <img src={arrow_up} alt='arrow'/>
                </div>
                }
            </div>
            : ''}

        </div>
    )
}

export default CardBlock;