import React, { useState, useEffect } from "react";
import { Space, Row, Col} from 'antd';
import { EntriesType } from "../../../assets/types/types";
import money from '../../../assets/icons/money-bill.svg';
import bag from '../../../assets/icons/briefcase.svg';
import { getOfferLink, showGeo } from "../../../helpers/functions";
import { Link, useLocation } from "react-router-dom";
import { onValue, ref, set } from "firebase/database";
import { db } from "../../../Firebase";
import { useAppDispatch } from "../../../app/hooks";
import { setModalResponse } from "../../../app/OfferSlice";

const Offer: React.FC<EntriesType> = (props) => {
    const location = useLocation();
    const browserParams = new URLSearchParams(location.search);
    const [counter, setCounter] = useState<number>(0);
    const [counterRespVacancy, setCounterRespVacancy] = useState<number>(0);

    const leadsId = props.id ? props.id : props.leads_id;
    const pathId = leadsId ? leadsId : props.ID;
    const geo = showGeo(props);

    let pathImg = '';
    props.icon.path[0] === '/' ? pathImg = `https://srochnoszaim.ru${props.icon.path}` :
    pathImg = `https://srochnoszaim.ru/${props.icon.path}`;

    const company = typeof (props.company) === 'string' ? props.company : props.company.name;
    const withModal = Boolean(company.toLowerCase() === 'сбермаркет');
    const dispatch = useAppDispatch();

    const handleResponseModal = () => {
        dispatch(setModalResponse({
            show_modal: true,
            offer: props
        }))
    }

    const handleCounterRespVacancy = () => {          
        set(ref(db, 'offers/' + pathId), {
            counterClick: counter + 1,
            responseVacancy: counterRespVacancy + 1,
            }).then(() => {
            
            }).catch((error) => {
            console.error("Ошибка записи данных в Realtime Database:", error);
            });
        
    }

    useEffect(() => { // запрос к базе данных на получение количества кликов по данному офферу
        const offerDB = ref(db, 'offers/' + pathId);
        onValue(offerDB, (snapshot) => {
            const dataDB = snapshot.val();
            if (dataDB) {
                dataDB.counterClick ? setCounter(dataDB.counterClick) : setCounter(0);
                dataDB.responseVacancy ? setCounterRespVacancy(dataDB.responseVacancy) : setCounterRespVacancy(0);
            } else {
                setCounter(0);
                setCounterRespVacancy(0);
            }
        })            
    }, [pathId])


    return (
        <div className="offer" onClick={()=>{console.log('offer_id', props.leads_id, props.id)}}>
            <div className="offer__title">
                <div className="offer__img">
                    <img src={pathImg} alt="sber"/>
                </div>
                <p>{typeof (props.company) === 'string' ? props.company : props.company.name}</p>
            </div>
            <h3>{props.name}</h3>
            <p className="offer-company-365">{typeof (props.company) === 'string' ? props.company : props.company.name}</p>
            <div className="card__info">
                <div>
                    <img src={money} alt="icon-money"/>
                    <span>
                        {props.wage_description.includes('₽') ? props.wage_description : `${props.wage_description} ₽`}
                    </span>
                </div>
                <div>
                    <img src={bag} alt="icon-bag"/>
                    <span>
                        {props.schedules.length > 1 ? props.schedules.map(x => x.name).join(', ') : props.schedules[0].name}
                    </span>
                </div>

            </div>
            {
                withModal ? 
                    <div className="card__buttons-apply button" onClick={handleResponseModal}>
                        Откликнуться
                    </div>
                :
                <Link to={getOfferLink(props, browserParams)} target="_blank">
                    <div className="card__buttons-apply button" style={{height: 50}} onClick={handleCounterRespVacancy}>
                        Откликнуться
                    </div>
                </Link>
            }
            <Space className="offer__description" direction="vertical">
                <Row gutter={[8,8]}>
                    <Col xs={24} xl={6}><h6>График</h6></Col>
                    <Col xs={24} xl={18}><p> {props.schedules.length > 1 ? props.schedules.map(x => x.name).join(', ') : props.schedules[0].name}</p></Col>
                </Row>
                <Row gutter={[8,8]}>
                    <Col xs={24} xl={6}><h6>Обязанности</h6></Col>
                    <Col xs={24} xl={18}
                        style={{textAlign: 'left'}}>
                        <p dangerouslySetInnerHTML={{ __html: props.excerpt.replace(/Обязанности:/g,'') }}>
                        </p>
                    </Col>
                </Row>
                <Row gutter={[0,8]}>
                    <Col xs={24} xl={6}><h6>Описание</h6></Col>
                    <Col xs={24} xl={18}
                        style={{textAlign: 'left'}}
                        dangerouslySetInnerHTML={{ __html: props.description }}> 
                    </Col>
                </Row>
                <Row gutter={[8,8]}>
                    <Col xs={24} xl={6}><h6>ГЕО</h6></Col>
                    <Col xs={24} xl={18}>
                        <p>{geo}</p>
                    </Col>
                </Row>

            </Space>
        </div>
    )
}

export default Offer;