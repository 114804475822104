import React, { useState, useEffect } from 'react';
import { ConfigProvider } from 'antd';
import { getAuth, signInAnonymously, onAuthStateChanged } from "firebase/auth";

import './assets/styles/styles.scss';
import './assets/styles/base.scss';
import './assets/styles/components/header.scss';
import './assets/styles/components/footer.scss';
import './assets/styles/components/card.scss';
import './assets/styles/components/card-block.scss';
import './assets/styles/components/offer.scss';
import './assets/styles/components/question-form.scss';
import './assets/styles/components/response-form.scss';
import './assets/styles/components/cookie.scss';

import MainPage from './components/main/MainPage';
import { useLocation, useNavigate } from 'react-router-dom';
import OfferPage from './components/main/offers/OfferPage';
import FooterComponent from './components/footer/Footer';
import axios from 'axios';
import { useAppDispatch, useAppSelector } from './app/hooks';
import { setAllOffers } from './app/OfferSlice';
import { setAffId, setOffersClick } from './app/FilterSlice';

import { CabinetOffersResponse, DBOfferCount, EntriesType, NewGeoType, OfferType } from './assets/types/types';
import { offersResponse } from './components/main/data/cabinetResponse';
import { getOfferWithNewGeo, isOfferCabinetType } from './helpers/functions';
import { onValue, ref } from 'firebase/database';
import { db } from './Firebase';
import CookieWarningBanner from './components/main/cookie/Cookie';
import { setAuth, setFirebaseUser } from './app/AuthSlice';
import WrapperSignUp from './components/main/Cabinet/WrapperSignUp';



const App: React.FC = () => {

  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState<boolean>(true);
    
  const history = useNavigate();
  const location = useLocation();
  const [params, setParams] = useState(new URLSearchParams(location.search));
  const [offerId, setOfferId] = useState(params.get('offer_id'));
    
  const showModal = useAppSelector(state => state.offers.modal_response.show_modal);
  const modalOffer = useAppSelector(state => state.offers.modal_response.offer);


  useEffect(() => {
    window.scrollTo(0,0);
    // console.log('scroll')
  }, [offerId]);
  
  useEffect(() => {

    const aff_id = params.get("affiliateId");
    // console.log('app:', params.toString())
      if (!aff_id) {  //если в строке запроса нет aff_id, подставляем дефолтный aff_id=183545
          params.set("affiliateId", "183545");
          setParams(new URLSearchParams(params.toString()));
          history(`/?${params.toString()}`);
          dispatch(setAffId('183545'));
          
      } else {
        dispatch(setAffId(aff_id));
        history(`/?${params.toString()}`);
      }
  }, [history, params, dispatch])

  useEffect(() => {
    const browserParams = new URLSearchParams(location.search);
    const offerId = browserParams.get('offer_id');
    // console.log('app use effect', offerId)

    if (offerId) {
      setOfferId(offerId);
    } else {
      setOfferId(null);
    }
  }, [location.search]);

  // function getOffersClick() {
  //   const auth = getAuth();
  //   signInAnonymously(auth)
  //     .then(() => {
  //       const offerDB = ref(db, 'offers');
  //       onValue(offerDB, (snapshot) => {
  //           const dataDB: DBOfferCount = snapshot.val();
  //           dispatch(setOffersClick(dataDB));
  //           console.log('dataDB', Object.entries(dataDB));
  //       })   
  //     })
  //     .catch((error) => {
  //       console.error(error)
  //     });
  // }

 // getOffersClick();

  useEffect(() => { // запрос к базе данных на получение количества кликов по данному офферу
    const auth = getAuth();
    signInAnonymously(auth)
      .then(() => {
        dispatch(setAuth(true));
        onAuthStateChanged(auth, (user) => {
          if (user) {
            const uid = user.uid;
            console.log(uid)
            dispatch(setFirebaseUser(uid));

            const offerDB = ref(db, 'offers');
            onValue(offerDB, (snapshot) => {
                const dataDB: DBOfferCount = snapshot.val();
                dispatch(setOffersClick(dataDB));

        })  
          }
        }) 
      })
      .catch((error) => {
        console.error(error)
      });
   
     
  }, [dispatch])
    

  useEffect(() => {  
    //загрузка офферов по апи
    const aff_id = params.get("affiliateId"); 
    setLoading(true);

    
    // <---------------------------------------для отладки на локальном хосте   

    // const apiUrl = `https://srochnoszaim.ru/api/collections/get/offers?token=12d336be28cf878395bde971a2bda3&populate=true`;
    // axios.get(apiUrl, {
    //   headers: {
    //     "Content-type": "application/json",
    //     "Accept": "*/*",
    //     // 'Accept-Encoding': 'gzip, deflate, br',

    //   },

    // }).then((resp) => {
    //     const allOffersResponse: OfferType = resp.data;
    //     const allOffers = allOffersResponse.entries; //массив с офферами
    //     //console.log('all', allOffers);
    //     const offersID: CabinetOffersResponse  = offersResponse; //массив с айдишниками офферов из кабинета
    //     const filterOffers = allOffers.filter((offer) => offersID.offers.some(off => off.id === offer.leads_id)); //фильруем по id
    //     const filterOffersAff = filterOffers.map((off) => { //создаем массив объектов, содержащих ключи объектов-офферов и id, id_platform
    //         const offer = offersID.offers.filter(offerId => offerId.id === off.leads_id)
    //         const newOffer: EntriesType = {...offer[0], ...off};
    //         return newOffer
    //     });
        
    //     if (aff_id === '183545') {
    //         const apiEgor = `https://srochnoszaim.ru/api/collections/get/offers_egor?token=12d336be28cf878395bde971a2bda3&populate=true`;
    //         axios
    //             .get(apiEgor, {
    //               headers: {
    //                 "Content-type": "application/json",
    //               }
    //             })
    //             .then((resp) => {
    //                 const egorOffersResp: OfferType = resp.data;
    //                 const egorOffers: EntriesType[] = egorOffersResp.entries;
    //                 const allWithEgorOffers = filterOffersAff.concat(egorOffers);      
    //                 const apiGeo = "https://script.google.com/macros/s/AKfycbyDYI-159UO5WxYJHsQmCgyndwiJbr2B3KqBfP3vafR7cvOEJ6cZ-FKdzaiSACs2TMy6Q/exec";
    //                 axios
    //                 .get(apiGeo)
    //                 .then((response) => {
    //                     const dataSheets: {status: string, data: Array<Array<Array<string>>>} = response.data;
    //                     const geo: NewGeoType[] = dataSheets.data.map((item) => {
    //                         return {id: item[0][0], geo: item.slice(1)}
    //                     })
    //                     const offersNewGeo = getOfferWithNewGeo(allWithEgorOffers, geo);
    //                     setLoading(false);
    //                     dispatch(setAllOffers(offersNewGeo));
        
    //                 })
    //                 .catch(error => {
    //                     console.error(error);
    //                 });
    //             })
    //     } else {

    //     const apiGeo = "https://script.google.com/macros/s/AKfycbyDYI-159UO5WxYJHsQmCgyndwiJbr2B3KqBfP3vafR7cvOEJ6cZ-FKdzaiSACs2TMy6Q/exec";
    //     axios
    //     .get(apiGeo)
    //     .then((response) => {
    //         const dataSheets: {status: string, data: Array<Array<Array<string>>>} = response.data;
    //         const geo: NewGeoType[] = dataSheets.data.map((item) => {
    //             return {id: item[0][0], geo: item.slice(1)}
    //         })
    //         const offersNewGeo = getOfferWithNewGeo(filterOffersAff, geo);
    //         setLoading(false);
    //         dispatch(setAllOffers(offersNewGeo));

    //     })
    //     .catch(error => {
    //         console.error(error);
    //     });
    //     }
        
    // })
    // .catch(error => {
    //     console.error(error);
    //     console.log('error 165')
    // });
      // -------------------------------------------------------------->


    // <----------------------------------------для билда

    const parameter = `affiliateId=${aff_id}`
    const api = `https://top-job.su/cabinet/index.php?${parameter}`; // запрос в кабинет по aff_id
    axios
        .get(api, {
          headers: {
            "Content-type": "application/json"
          }
        })
        .then((resp) => { 
            if (resp && isOfferCabinetType(resp.data)) { // если есть офферы по данному aff_id, формируется массив офферов (id, id_platform)
                //console.log('кабинет', resp, resp.data)
                const offersID: CabinetOffersResponse = resp.data;
                const apiUrl = `https://srochnoszaim.ru/api/collections/get/offers?token=12d336be28cf878395bde971a2bda3&populate=true`;
                axios.get(apiUrl, {
                  headers: {
                    "Content-type": "application/json"        
                  }
                }).then((resp) => {
                    if (resp && resp.data) {
                        const allOffersResponse: OfferType = resp.data;
                        const allOffers = allOffersResponse.entries;
                        const filterOffers = allOffers.filter((offer) => offersID.offers.some(off => off.id === offer.leads_id));
                        
                        //создаем массив объектов, содержащих ключи объектов-офферов и id, id_platform
                        const filterOffersAff = filterOffers.map((off) => { 
                            const offer = offersID.offers.filter(offerId => offerId.id === off.leads_id)
                            const newOffer: EntriesType = {...offer[0], ...off};
                            return newOffer
                            })

                        if (aff_id === '183545') {
                            const apiEgor = `https://srochnoszaim.ru/api/collections/get/offers_egor?token=12d336be28cf878395bde971a2bda3&populate=true`;
                            axios
                                .get(apiEgor, {
                                  headers: {
                                    "Content-type": "application/json"                       
                                  }
                                })
                                .then((resp) => {
                                    const egorOffersResp: OfferType = resp.data;
                                    const egorOffers: EntriesType[] = egorOffersResp.entries;
                                    const allWithEgorOffers = filterOffersAff.concat(egorOffers);

                                    const apiGeo = "https://script.google.com/macros/s/AKfycbyDYI-159UO5WxYJHsQmCgyndwiJbr2B3KqBfP3vafR7cvOEJ6cZ-FKdzaiSACs2TMy6Q/exec";
                                    axios
                                    .get(apiGeo)
                                    .then((response) => {
                                        const dataSheets: {status: string, data: Array<Array<Array<string>>>} = response.data;
                                        const geo: NewGeoType[] = dataSheets.data.map((item) => {
                                            return {id: item[0][0], geo: item.slice(1)}
                                        })
                                        const offersNewGeo = getOfferWithNewGeo(allWithEgorOffers, geo);
                                        setLoading(false);
                                        dispatch(setAllOffers(offersNewGeo))
                                    })
                                    .catch(error => {
                                        console.error(error);
                                    });


                                })
                                .catch((error) => {
                                    console.error(error);
                                })
                            } else {
                                const apiGeo = "https://script.google.com/macros/s/AKfycbyDYI-159UO5WxYJHsQmCgyndwiJbr2B3KqBfP3vafR7cvOEJ6cZ-FKdzaiSACs2TMy6Q/exec";
                                axios
                                .get(apiGeo)
                                .then((response) => {
                                    const dataSheets: {status: string, data: Array<Array<Array<string>>>} = response.data;
                                    const geo: NewGeoType[] = dataSheets.data.map((item) => {
                                        return {id: item[0][0], geo: item.slice(1)}
                                    })
                                    const offersNewGeo = getOfferWithNewGeo(filterOffersAff, geo);
                                    setLoading(false);
                                    dispatch(setAllOffers(offersNewGeo))
                                })
                                .catch(error => {
                                    console.error(error);
                                });
                            }


                    } else {
                        setLoading(false);
                        dispatch(setAllOffers([]));
                        console.log('Нет данных по вакансиям');
                    }
                    })
                    .catch(error => {
                        console.error(error);
                    });
            } else {  // если нет офферов по данному aff_id, нужно вернуть данные по дефолтному aff_id=183545
                params.set("affiliateId", "183545");
                setParams(new URLSearchParams(params.toString()));
                // history(`/?${params.toString()}`);
            }
        })
        .catch(error => { // при ошибке - загрузка всех офферов системы
            console.error(error);
            const apiUrl = `https://srochnoszaim.ru/api/collections/get/offers?token=12d336be28cf878395bde971a2bda3&populate=true`;
            axios.get(apiUrl, {
              headers: {
                "Content-type": "application/json"                 
              }
            }).then((resp) => {
                const allOffers: OfferType = resp.data;
                setLoading(false);
                dispatch(setAllOffers(allOffers.entries))
                })
              .catch(error => {
                console.log('тут')
                console.error(error);
              });
        });
    // ------------------------------------------------>

    
}, [setLoading, dispatch, params]);

  return (
    <ConfigProvider
      theme={{
        token: {   
            fontFamily: 'Montserrat, Ubuntu, san-serif'
        }
      }}
    >
      <div className="App">
        {offerId ? <OfferPage load={loading}/> : <MainPage load={loading} /> }
        {showModal && modalOffer ? 
                <div className='response-form-layout'>
                    <WrapperSignUp {...modalOffer} />
                </div>
                :
                <></>
      }
        <FooterComponent/>
        <CookieWarningBanner/>
      </div>
    </ConfigProvider>
  );
}

export default App;
