import { Button, Checkbox, Form, Modal } from 'antd';
//import { MaskedInput } from 'antd-mask-input';
import React, { useState } from 'react';
import "firebase/auth";
import FirstName from './FormItem/FirstName';
import Gender from './FormItem/Gender';
import Age from './FormItem/Age';
import Phone2 from './FormItem/Phone2';
import Email from './FormItem/Email';
import LastName from './FormItem/LastName';
import City from './FormItem/City';
import { EntriesType } from '../../../assets/types/types';
import { useAppDispatch } from '../../../app/hooks';
import { setModalResponse } from '../../../app/OfferSlice';
import axios from 'axios';

const ResponseForm: React.FC<EntriesType> = (props) => {

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [city, setCity] = useState('');
    const [gender, setGender] = useState('');
    const [age, setAge] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [checkbox, setCheckbox] = useState(false);
    const [errorEmail, setErrorEmail] = useState('');
    const [error, setError] = useState('');  

    const dispatch = useAppDispatch();

    const successModal = () => {
        Modal.success({
            title: 'Ваш отклик отправлен!',
            content: 'В ближайшее время мы свяжемся с Вами, расскажем о вакансии более подробно и поможем начать! Ожидайте звонка',
            afterClose() {
                dispatch(setModalResponse({
                        show_modal: false,
                        offer: null,
                    }));
            },
        });
    };

    const errorModal = () => {
        Modal.error({
          title: 'Произошла ошибка!',
          content: 'Возникли непредвиденные осложения, попробуйте еще раз',
        });
    };
    
    const handleSubmit = async (value: any) => {

        const url = 'https://bpium.hrfamily.pro:8443/topjob_import';
        
        const todayDate = new Date()
        const data = {
            "first_name": firstName,
            "last_name": lastName,
            "phone": phone,
            "email": email,
            "age": age,
            "gender": gender,
            "city": city,
            "vacancy_id": props.geoID || props.leads_id || props.ID,
            "date": todayDate.toISOString()
        }

        // const headers = {
        //     "Content-Type": "application/json"
        //   }
        console.log(data)
        
        try {
            const response = await axios.post(url, data);
            console.log('Response:', response.data);
            setError('');
            setErrorEmail('');
            successModal();
            
            // dispatch(setModalResponse({
            //     show_modal: false,
            //     offer: null,
            // }));
        } catch (error) {
            console.error('Error:', error);
            errorModal();
            // Обработка ошибок
        }
    };

    const handleClose = () => {
        dispatch(setModalResponse({
            show_modal: false,
            offer: null,
        }))
    }

    return (
        <div className='wrapper_form'>
            <div className='form_title'>
                <h3>Форма обратной связи</h3>
                <p>Ответьте на несколько вопросов, и наш менеджер свяжется с Вами в ближайшее время</p>
            </div>
            <div className='signup_content'>
                <Form
                    onFinish={handleSubmit}
                    autoComplete='off'
                >
                    <FirstName setFirstName={setFirstName}/>
                    <LastName setLastName={setLastName}/>
                    <Phone2 setPhone={setPhone}/>
                    <Email setEmail={setEmail} error={errorEmail ? errorEmail : undefined}/>
                    <Gender setGender={setGender}/>
                    <Age setAge={setAge}/>
                    <City setCity={setCity} options={props.geo} />
                    <Form.Item
                        name="agreement"
                        valuePropName="checked">
                        <Checkbox
                            onChange={(e) => {setCheckbox(e.target.checked)}}>
                            С правилами обработки персональных данных ознакомлен.
                        </Checkbox>
                    </Form.Item>
                    <Form.Item>
                        <Button 
                            type='primary'
                            htmlType="submit"  
                            disabled={firstName && lastName && gender && age && phone && email && city && checkbox ? false : true} 
                            className='signup_button_reg'
                        >
                            Отправить
                        </Button> 
                        {error ? <p>{error}</p> : ''} 
                    </Form.Item>
                </Form>
            </div>
            <div className='question-form__close-btn' onClick={handleClose}>
                    X
            </div>
        </div>
    )
}

export default ResponseForm;