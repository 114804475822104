import { configureStore } from '@reduxjs/toolkit';
import offersReducer from './OfferSlice';
import filterReducer from './FilterSlice';
import questionReducer from './QuestionFormSlice';
import authReducer from './AuthSlice';


export const store = configureStore({
    reducer: {
        offers: offersReducer,
        filters: filterReducer,
        questions: questionReducer,
        auth: authReducer,
    },
    middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
    devTools: true,
});

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch;