import React from 'react';
import { Form } from 'antd';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import ru from 'react-phone-input-2/lang/ru.json'


interface ChildProps {
    setPhone: React.Dispatch<React.SetStateAction<string>>;
}


const Phone2: React.FC<ChildProps> = ( {setPhone}) => {

  
    return (
        <Form.Item
            name="phone"
            label="Номер телефона"
            rules={[{ required: true, message: 'Поле обязательно для заполнения' }]}>

            <PhoneInput
            country={'ru'}
            localization={ru}
            onlyCountries={['ru', 'by', 'az', 'am', 'kz', 'kg', 'md', 'tj', 'tm', 'uz', 'ua']}
            //value={this.state.phone}
            onChange={phone => setPhone(phone)}
            />
        </Form.Item>
    )
}

export default Phone2;