import { createSlice, current, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "./store";
import { EntriesType, offerState } from '../assets/types/types';
import { sortByCategories } from "../helpers/filterFunctions";


const initialState: offerState = {
    offers: [],
    stateOffers: [],
    offersCat: {
        categories: {
            courier: [],
            partTime: [],
            noExper: [],
            home: [],
            stock: [],
            watch: [],
            other: []
        },
    },
    modal_response: {
        show_modal: false,
        offer: null,
    }
}

function getNewFiled(offers: EntriesType[]) {
    const fields = offers.map((item) => {
        return {
            ID: item.ID,
            id: item.leads_id,
            company: item.company.name,
            citizen: item.citizenship,
            ageto: item.age_to,
            agefrom: item.age_from
        }
    })
    return fields
    //console.log('new fields', fields)
}


export const offerSlice = createSlice({
    name: 'offers',
    initialState: initialState,
    reducers: {
        setAllOffers: (state, action: PayloadAction<EntriesType[]>) => {
            state.offers = action.payload;
            const filteredOffers = sortByCategories(action.payload);
            state.offersCat.categories = { ...state.offersCat.categories, ...filteredOffers.categories };
            getNewFiled(action.payload);
        },
        addOffers: (state, action: PayloadAction<EntriesType[]>) => {
            const dataOffers = current(state.offers);
            state.offers = dataOffers.concat(action.payload);
            const filteredOffers = sortByCategories(state.offers);
            state.offersCat.categories = { ...state.offersCat.categories, ...filteredOffers.categories };
            getNewFiled(action.payload);
        },
        setFilterOffers: (state, action: PayloadAction<EntriesType[]>) => {
            state.stateOffers = action.payload;
        },
        setSortOffers: (state, action: PayloadAction<EntriesType[]>) => {
            //state.stateOffers = action.payload;
            const filteredOffers = sortByCategories(action.payload);
            state.offersCat.categories = { ...state.offersCat.categories, ...filteredOffers.categories };
        },
        setModalResponse: (state, action: PayloadAction<{show_modal: boolean, offer: EntriesType | null}>) => {
            state.modal_response.show_modal = action.payload.show_modal;
            state.modal_response.offer = action.payload.offer;
            const body = document.body;
            if (action.payload.show_modal) {
                body.style.overflow = 'hidden'
            } else {
                body.style.overflow = 'auto'
            }
        }
        
    }
})

export const { setAllOffers, addOffers, setFilterOffers, setSortOffers, setModalResponse } = offerSlice.actions;

export const selectOfferState = (state: RootState) => state.offers;

export default offerSlice.reducer;