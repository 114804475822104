import React from 'react';
import logo from '../../assets/img/logo.svg';
import telegram from '../../assets/icons/telegram.svg';
import { Link } from 'react-router-dom';
import { useAppSelector } from '../../app/hooks';

const HeaderLogo: React.FC = () => {

    const aff_id = useAppSelector(state => state.filters.aff_id);
    const pathHome = `/?affiliateId=${aff_id}`;
           
    return (       
            <div className='header__logo'>
                <Link to={pathHome}>
                    <div>
                        <img src={logo} alt='logo' />
                    </div>
                </Link>
                <div>
                    <img src={telegram} alt='tel'/>
                </div>
            </div>
    )
}

export default HeaderLogo;