import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "./store";
import { DBOfferCount, filterState } from '../assets/types/types';
//import { filter } from "../helpers/filterFunctions";


const initialState: filterState = {
    filters: {
        position: [],
        city: [],
        filterTags: {
            minSalary: 0,
            tags: []
        },
    },
    sort: 'default',
    aff_id: '',
    offersClick: {},
}


export const filterSlice = createSlice({
    name: 'filters',
    initialState: initialState,
    reducers: {
        setStateFilterPosition: (state, action: PayloadAction<string[]>) => {
            state.filters.position = action.payload;
            // const dataOffer = current(state.offers);
            // const dataFilter = current(state.filters);
            // state.stateOffers = filter(dataOffer, dataFilter);

            // localStorage.setItem('stateTJ', JSON.stringify(state));
        },
        setStateFilterCity: (state, action: PayloadAction<string[]>) => {
            state.filters.city = action.payload;
            
          //  const dataOffer = current(state.offers);
          //  const dataFilter = current(state.filters);
          //  state.stateOffers = filter(dataOffer, dataFilter);
         
            // localStorage.setItem('stateTJ', JSON.stringify(state));
        },
        setAffId: (state, action: PayloadAction<string>) => {
            state.aff_id = action.payload;
            // localStorage.setItem('stateTJ', JSON.stringify(state));
        },
        setStateFilterTagSalary: (state, action: PayloadAction<number>) => {
            state.filters.filterTags.minSalary = action.payload;
            
         //   const dataOffer = current(state.offers);
          //  const dataFilter = current(state.filters);
           // state.stateOffers = filter(dataOffer, dataFilter);
            // localStorage.setItem('stateTJ', JSON.stringify(state));
        },
        setStateFilterTag: (state, action: PayloadAction<string[]>) => {
            state.filters.filterTags.tags = action.payload;
       //     const dataOffer = current(state.offers);
        //    const dataFilter = current(state.filters);
         //   state.stateOffers = filter(dataOffer, dataFilter);
        
            // localStorage.setItem('stateTJ', JSON.stringify(state));
        },   
        setOffersClick: (state, action: PayloadAction<DBOfferCount>) => {
            state.offersClick = action.payload;
            // console.log('setOffersClick', state.offersClick)
            // localStorage.setItem('stateTJ', JSON.stringify(state));
        },
        setSort: (state, action: PayloadAction<string>) => {
            state.sort = action.payload;
        }
        
    }
})

export const {  
    setStateFilterPosition, 
    setStateFilterCity, 
    setAffId, 
    setStateFilterTagSalary, 
    setStateFilterTag,
    setOffersClick,
    setSort,
 } = filterSlice.actions;

export const selectFilterState = (state: RootState) => state.filters;

export default filterSlice.reducer;