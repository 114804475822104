import React, { useEffect, useState } from "react";
import money from '../../../assets/icons/money-bill.svg';
import bag from '../../../assets/icons/briefcase.svg';
import { Link, useLocation, useNavigate } from "react-router-dom";
import { EntriesType } from "../../../assets/types/types";
import { getOfferLink } from "../../../helpers/functions";
import { onValue, ref, set } from "firebase/database";
import { auth, db } from "../../../Firebase";
import { useAppDispatch } from "../../../app/hooks";
import { setModalResponse } from "../../../app/OfferSlice";
//import { useAppSelector } from "../../../app/hooks";
//import arrow from '../../../assets/icons/arrow.svg';

const Card: React.FC<EntriesType> = (props) => {
    const leadsId = props.geoID ? props.geoID : props.leads_id ? props.leads_id : props.ID;
    const pathId = leadsId;
    // const path = '/offers/' + pathId;
    const location = useLocation();
    const history = useNavigate();
    const browserParams = new URLSearchParams(location.search);
    const [params, setParams] = useState(new URLSearchParams(location.search));
    const path = '/?affiliateId=' + params.get('affiliateId') + '&offer_id=' + pathId;
    let pathImg = '';
    props.icon.path[0] === '/' ? pathImg = `https://srochnoszaim.ru${props.icon.path}` :
    pathImg = `https://srochnoszaim.ru/${props.icon.path}`;
    const [counter, setCounter] = useState<number>(0);
    const [counterRespVacancy, setCounterRespVacancy] = useState<number>(0);

    const company = typeof (props.company) === 'string' ? props.company : props.company.name;
    const withModal = Boolean(company.toLowerCase() === 'сбермаркет');
    const dispatch = useAppDispatch();


    const handleClick = () => {
        params.set("offer_id", pathId);
        setParams(new URLSearchParams(params.toString()));
        history(`/?${params.toString()}`);

    }
   
    const handleClickCounter = (e: React.MouseEvent<HTMLDivElement>) => {

        const isResponseButton = e.target instanceof HTMLAnchorElement && e.target.classList.contains('card__buttons-apply');
        if (auth) {
            if (isResponseButton) {            
                set(ref(db, 'offers/' + pathId), {
                    counterClick: counter + 1,
                    responseVacancy: counterRespVacancy + 1,
                    }).then(() => {
    
                    }).catch((error) => {
                    console.error("Ошибка записи данных в Realtime Database:", error);
                    });
            } else {
                set(ref(db, 'offers/' + pathId), {
                    counterClick: counter + 1,
                    responseVacancy: counterRespVacancy,
                    }).then(() => {
                    
                    }).catch((error) => {
                    console.error("Ошибка записи данных в Realtime Database:", error);
                    });
            }
        }
    }

    const handleResponseModal = () => {
        dispatch(setModalResponse({
            show_modal: true,
            offer: props
        }))
    }
    
    useEffect(() => { // запрос к базе данных на получение количества кликов по данному офферу
        const offerDB = ref(db, 'offers/' + pathId);
        onValue(offerDB, (snapshot) => {
            const dataDB = snapshot.val();
            if (dataDB) {
                dataDB.counterClick ? setCounter(dataDB.counterClick) : setCounter(0);
                dataDB.responseVacancy ? setCounterRespVacancy(dataDB.responseVacancy) : setCounterRespVacancy(0);
            } else {
                setCounter(0);
                setCounterRespVacancy(0);
            }
        })            
    }, [pathId])


    return (
            <div className="card" onClick={() => console.log('offer_id', props.leads_id, props.id, props.ID, 'geoId: ', props.geoID)}>
                <div className="card__content" onClick={handleClickCounter}>
                    <Link to={path} className="card__title">
                        <div className="card-position">
                            <h5><span style={{color: '#0A0E20', fontWeight: 600}}>
                                {props.name}
                            </span></h5>
                            <h5>в   {typeof (props.company) === 'string' ? props.company : props.company.name}</h5>
                            
                            {/* {window.location.host === 'localhost:3000' ? 
                                <div>
                                    <h5>{props.citizenship}</h5>
                                    <h5>{props.age_from}</h5>
                                    <h5>{props.age_to}</h5>
                                </div>
                                
                                : <></>
                            } */}


                        </div>
                        <div className="card__info">
                            <div>
                                <img src={money} alt="icon-money"/>
                                <span>
                                    {props.wage_description.includes('₽') ? props.wage_description : `${props.wage_description} ₽`}
                                </span>
                            </div>
                            <div>
                                <img src={bag} alt="icon-bag"/>
                                <span>
                                    {props.schedules.length > 1 ? props.schedules.map(x => x.name).join(', ') : props.schedules[0].name}
                                </span>
                            </div>

                        </div>
                    </Link>
                    <Link to={path} className="card__company">
                        <div className="card__company-img">
                            <img src={pathImg} alt="icon-company"/>
                        </div>
                    </Link>
                    <div className="card__buttons">
                        {
                            withModal ? 
                            <div className="card__buttons-apply button" onClick={handleResponseModal}>
                                Откликнуться
                            </div>
                            :
                            <Link to={getOfferLink(props, browserParams)} target="_blank" className="card__buttons-apply button">
                                Откликнуться
                        </Link>
                        }
                        <div onClick={handleClick} className="card__buttons-more button-transparent">
                            <span>Подробнее о вакансии</span>
                            <svg className="arrow" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd" d="M19.8561 9.44613C21.2719 10.7563 22.935 11.5361 23.8709 11.7851L24 13.3676C22.8782 13.8982 21.1795 14.9434 19.7753 16.3248C18.3526 17.7245 17.3649 19.3356 17.3649 21L15.7863 21C15.7863 18.6324 17.1665 16.6035 18.7037 15.0912C19.3315 14.4735 20.0078 13.9197 20.673 13.44L-3.30458e-07 13.44L-3.33955e-07 11.76L20.1202 11.76C19.6797 11.4515 19.2405 11.1027 18.8203 10.7139C17.202 9.21627 15.7863 7.04384 15.7863 4.2L17.3649 4.2C17.3649 6.39616 18.4487 8.14373 19.8561 9.44613Z" fill="#117FFF"/>
                            </svg>
                        </div>

                    </div>
                    <Link to={path} className="card__tags">
                        {Array.isArray(props.tags) && props.tags ? props.tags.map((tag, index) => (
                            <div className="tag" key={index} onClick={(e) => {e.preventDefault()}}>
                                {tag.name}
                            </div>
                        ))
                        : 
                        <div className="tag">
                            {props.tags}
                        </div>
                        // доработать
                        }
                    </Link>
                </div>
            </div>
    )
}

export default Card;