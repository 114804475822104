import React from 'react';
import { Footer } from 'antd/es/layout/layout';
import { Col, Row } from 'antd';
import telegram from '../../assets/icons/telegram.png';

const FooterComponent: React.FC = () => {
    return (
        <Footer style={{backgroundColor: '#fff'}}>
            <Row className='footer__row'>
                <Col className='footer__col-1'>
                    <p style={{color: '#595D6F'}}>
                    Copyright © LEADS.SU 2010–2023.<br/>
                    Все права защищены.
                    </p>
                </Col>
                <Col className='footer__col-2'>
                    <a href='/' target='_blank' rel='referrer' >
                        <img src={telegram} alt='telegram' className='footer__icon'/>
                    </a>
                </Col>
                <Col className='footer__col-3'>
                    <p style={{color: '#595D6F'}}>
                        +7 (800) 500-2423
                    </p>
                    <p style={{color: '#318EE3'}}>support@leads.su</p>
                </Col>
            </Row>
        </Footer>
    )
}

export default FooterComponent;