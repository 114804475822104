import { Select } from 'antd';
import React, { useState, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { getCityToOptions, getPositionToOptions } from '../../helpers/functions';
import { setStateFilterCity, setStateFilterPosition } from '../../app/FilterSlice';
import { filter } from '../../helpers/filterFunctions';
import { setFilterOffers } from '../../app/OfferSlice';


const FilterPositionCity: React.FC = () => {
    const state = useAppSelector(state => state.filters); // текущие фильтры
    const stateOffers = useAppSelector(state => state.offers.offers); // массив офферов

    const [geo, setGeo] = useState<{ value: string; label: string; }[]>([]);
    const [position, setPosition] = useState<{ value: string; label: string; }[]>([]);
    const dispatch = useAppDispatch();
    
    const handleChangePosition = (value: string[]) => { // [курьер, кассир]
        dispatch(setStateFilterPosition(value));
    };
    
    const handleChangeCity = (value: string[]) => { // [Москва, Рязань]
        console.log(value)
        dispatch(setStateFilterCity(value))
    };

    useEffect(() => {
        const filteredOffers = filter(stateOffers, state.filters);
        dispatch(setFilterOffers(filteredOffers))
    }, [dispatch, state.filters, stateOffers])

    useEffect(() => { 
        const optionsPosition = getPositionToOptions(stateOffers);
        setPosition(optionsPosition);
        const optionsCity = getCityToOptions(stateOffers);
        setGeo(optionsCity);
    }, [stateOffers])

    return (
        <div className='header__select'>
            <Select
                showSearch
                showArrow
                mode="multiple"
                allowClear
                placeholder="Должность"
                onChange={handleChangePosition}
                filterOption={(input, option) =>
                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }
                filterSort={(optionA, optionB) =>
                (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                }
                options={position}
                defaultValue={state.filters.position}
            />
            <Select
                showSearch
                showArrow
                mode="multiple"
                allowClear
                placeholder="Город"
                onChange={handleChangeCity}
                filterOption={(input, option) =>
                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }
                options={geo}
                defaultValue={state.filters.city}
            />
        </div>
    )
}

export default FilterPositionCity;