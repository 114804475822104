import { Button } from 'antd';
import React, { useState, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { setStateFilterTag, setStateFilterTagSalary } from '../../app/FilterSlice';
import { filter } from '../../helpers/filterFunctions';
import { setFilterOffers } from '../../app/OfferSlice';

const FilterTags: React.FC = () => {

    const state = useAppSelector(state => state.filters);
    const stateOffers = useAppSelector(state => state.offers.offers); // массив офферов

    const [isSalaryTagActive, setIsSalaryTagActive] = useState(Boolean(state.filters.filterTags.minSalary));
    const [activeTags, setActiveTags] = useState<string[]>(state.filters.filterTags.tags);

    const dispatch = useAppDispatch();


    const handleTagSalary = () => {
        !isSalaryTagActive ? dispatch(setStateFilterTagSalary(50000)) : dispatch(setStateFilterTagSalary(0));
        setIsSalaryTagActive(!isSalaryTagActive);
    }

    const handleTagClick = (tag: string) => {
        const isTagActive = activeTags.includes(tag);

        if (isTagActive) {
          setActiveTags(activeTags.filter((activeTag) => activeTag !== tag));
        //   dispatch(setStateFilterTag(activeTags));
        } else {
          setActiveTags([...activeTags, tag]);
        //   dispatch(setStateFilterTag(activeTags));
        }
    };

    useEffect(() => {
        dispatch(setStateFilterTag(activeTags));
        const filteredOffers = filter(stateOffers, state.filters);
        dispatch(setFilterOffers(filteredOffers));
    }, [activeTags, dispatch, stateOffers, state.filters])

    return (
        <div className='header__tags'>
                <Button
                    className='antd-tag'
                    type={activeTags.includes('Удаленка') ? 'primary' : 'default'}
                    onClick={() => handleTagClick('Удаленка')}>
                    Удаленка
                </Button>
                <Button
                    className='antd-tag'
                    type={isSalaryTagActive ? 'primary' : 'default'}
                    onClick={handleTagSalary}>
                    Зарплата от 50 т.р.
                </Button>
                <Button
                    className='antd-tag'
                    type={activeTags.includes('Пеший курьер') ? 'primary' : 'default'}
                    onClick={() => handleTagClick('Пеший курьер')}>
                    Пеший курьер
                </Button>
                <Button
                    className='antd-tag'
                    type={activeTags.includes('Без опыта') ? 'primary' : 'default'}
                    onClick={() => handleTagClick('Без опыта')}>
                    Без опыта
                </Button>
            </div>
    )
}

export default FilterTags;