import React, { useState, useEffect } from 'react';
import HeaderLogo from '../../header/HeaderLogo';
import ShortCardJob from './ShortCardJob';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Offer from './Offer';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { Spin } from 'antd';
import { setAffId } from '../../../app/FilterSlice';
import { findOtherJob } from '../../../helpers/functions';
import { EntriesType } from '../../../assets/types/types';

interface offerPageProps {
    load: boolean;
  }

const OfferPage: React.FC<offerPageProps> = ({load}) => {
    const history = useNavigate();
    const dispatch = useAppDispatch();
    const location = useLocation();
    const [params, setParams] = useState(new URLSearchParams(window.location.search));
    const [id, setOfferId] = useState(params.get('offer_id'));

    const stateOffers = useAppSelector(state => state.offers.offers);
    const offer = stateOffers.find(item => (item.geoID === id || item.ID === id || item.leads_id === id));
    
    const allOffers = useAppSelector(state => state.offers.offers);
    let otherJob: EntriesType[] = [];
    if (offer) {
        otherJob = findOtherJob(allOffers, offer);
    }


    useEffect(() => {
        window.scrollTo(0,0);
    }, [id]);
    
    useEffect(() => {
        const browserParams = new URLSearchParams(location.search);
        const offerId = browserParams.get('offer_id');    
        offerId ?  setOfferId(offerId) : setOfferId(null);
    }, [location.search]);
    

    useEffect(() => {
        const newParams = new URLSearchParams(window.location.search);
        setParams(newParams);
    
        const aff_id = newParams.get("affiliateId");
        if (!aff_id) {
            newParams.set("affiliateId", "183545");
            history(`/offers/${id}/?${newParams.toString()}`);
            dispatch(setAffId('183545'));
        } else {
            dispatch(setAffId(aff_id));
        }
    }, [history, id, dispatch]);


    return (         
        <div className='offer-page-loading-wrapper'>
            {!load && offer ? 
                <div className='offer-page-wrapper'>
                    <div className='offer-page__offer'>
                        <HeaderLogo/>
                        <div className='link-back'>
                            <Link to={`/?affiliateId=${params.get("affiliateId")}`}>
                            {/* <Link to='/'> */}
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M4.14387 15.7538C2.72807 14.4436 1.06495 13.6638 0.129135 13.4149L9.10154e-08 11.8323C1.12182 11.3017 2.82047 10.2566 4.22466 8.87514C5.64737 7.47546 6.63509 5.86432 6.63509 4.19995L8.21371 4.19995C8.21371 6.56758 6.83348 8.59644 5.29627 10.1088C4.66847 10.7264 3.99221 11.2803 3.32696 11.76L24 11.76L24 13.44L3.8798 13.44C4.32028 13.7485 4.7595 14.0972 5.17973 14.4861C6.79805 15.9837 8.21371 18.1561 8.21371 21L6.63509 21C6.63509 18.8038 5.55126 17.0562 4.14387 15.7538Z" fill="#117FFF"/>
                                </svg>
                                <span>На главную</span>
                            </Link>
                        </div>
                        <Offer {...offer}/>
                    </div>
                    <div className='offer-page__other-job'> 
                        <h4>Похожие вакансии</h4>
                        <div className='other-job-list'>
                            {otherJob.map((job, index) => (
                                <div key={index}>
                                    <ShortCardJob {...job}/>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                :
                load ?
                    <div className="loading_screen">
                        <Spin size="large" />
                    </div>
                :
                    <div className="loading_screen">
                        <h3>Не найдено</h3>
                    </div>
            }  
        </div>
    )    
}

export default OfferPage;