import React from 'react';
import { Form, Input } from 'antd';


interface ChildProps {
    setAge: React.Dispatch<React.SetStateAction<string>>;
}

const Age: React.FC<ChildProps> = ( {setAge}) => {

    return (
        <Form.Item
            name="age"
            label="Возраст"
            rules={[{ required: true, message: 'Поле обязательно для заполнения' }]}>
            <Input 
                placeholder="Возраст (введите число)" 
                className='signup_input'
                type='number'
                prefix={<label className="floating_label disabled" htmlFor="age" id="label_age">Возраст</label>}
                onChange={(e) => {
                    const label = document.getElementById('label_age') as HTMLElement;
                    if (e.target.value !== '') {
                        label.classList.remove('disabled');
                        setAge(e.target.value)
                        console.log(typeof e.target.value)
                    } else {
                        label.classList.add('disabled');
                        setAge('')
                    }
                }}
                />
        </Form.Item>
    )
}

export default Age;