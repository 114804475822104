import React, { useState } from 'react';
import { Form, Input, Select } from 'antd';
import { GeoType } from '../../../../assets/types/types';

interface ChildProps {
    setCity: React.Dispatch<React.SetStateAction<string>>,
    options: GeoType[],
}


const City: React.FC<ChildProps> = ( {setCity, options} ) => {

    const [showCityOther, setShowCityOther] = useState(false);
    
    const getCityToOptions = () => {
        const sityArr = options.map(geo => geo.name);
        const optionsCity = sityArr.map((item) => {   //создаем массив объектов вида {value: string, label: string}
            return {value: item, label: item}
        })
        const other = {
            value: 'other',
            label: 'Свой вариант'
        }
        optionsCity.push(other);
        return optionsCity;
    }


    const handleChangeCity = (value: string) => {
        console.log(value)
        if (value === 'other') {
            setShowCityOther(true);
        } else {
            setShowCityOther(false);
            setCity(value);
        }    
    }

    return (
        <>
        <Form.Item
            name="City"
            label='Город'
            rules={[{ required: true, message: 'Поле обязательно для заполнения' }]}>
            <Select 
                placeholder="Город" 
                className='signup_select signup_select_country'
                onChange={handleChangeCity}
                options={getCityToOptions()}
                optionLabelProp="label">
            </Select>
        </Form.Item>
        {showCityOther ? 
                <Form.Item
                name="city-other"
                label='Свой город'
                rules={[{ required: true, message: 'Поле обязательно для заполнения' }]}>
                <Input
                    type='text'
                    placeholder="Введите свой город" 
                    className='signup_input'
                    prefix={<label className="floating_label disabled" htmlFor="city-other" id="label_city-other">Свой город</label>}
                    onChange={(e) => {
                        const label = document.getElementById('label_city-other') as HTMLElement;
                        if (e.target.value !== '') {
                            label.classList.remove('disabled');
                            setCity(e.target.value)
                        } else {
                            label.classList.add('disabled');
                            setCity('')
                        }
                    }}
                    />
            </Form.Item>
            : <></>
        }
        </>
    )
}

export default City;