import React, { useEffect } from 'react';
import CardBlock from './CardBlock';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { offersCategories } from '../../../helpers/functions';
import { sortOffersByClick } from '../../../helpers/filterFunctions';
import { setSortOffers } from '../../../app/OfferSlice';
//import { setSort } from '../../../app/FilterSlice';

const CardBlockWrapper: React.FC = () => {
    const offerStateCat = useAppSelector(state => state.offers.offersCat);
    const dataCategory = offersCategories(offerStateCat);
    const stateOffers = useAppSelector(state => state.offers.stateOffers);
    const allOffers = useAppSelector(state => state.offers.offers);
    const stateFilters = useAppSelector(state => state.filters.filters);
    const isFilter = (stateFilters.city.length || stateFilters.position.length 
    || stateFilters.filterTags.tags.length || stateFilters.filterTags.minSalary) ? true : false;
    const qustionsCompleted = useAppSelector(state => state.questions.questionFormFilled)
    // console.log('stateoffer', stateOffers)
    const dispatch = useAppDispatch();
   // const [sort, setSort] = useState('');

    const clicks = useAppSelector(state => state.filters.offersClick)

    useEffect(() => {
        const sortOffers = sortOffersByClick(allOffers, clicks);
        dispatch(setSortOffers(sortOffers))
    }, [dispatch, clicks, allOffers])

    return (
        <div className='card-block-wrapper'>
            
            {isFilter || qustionsCompleted ? 
                stateOffers.length ?
                    <div>
                        <CardBlock title='Подобрали для Вас:' img='' offers={sortOffersByClick(stateOffers, clicks)}/>
                        {
                            dataCategory.map((item, index) => (
                                item.offers.length ? 
                                <div key={index}>
                                    <CardBlock {...item}/>
                                </div>
                                : 
                                <div key={index} style={{height: 0}}></div>
                            ))
                        }
                    </div>
                    :
                    <div className='card-block-not-found'>
                        <h3>К сожалению, у нас сейчас нет подходящих вам вакансий. Но вы можете изучить наши вакансии и, возможно, найти что-то интересное для себя</h3>
                        {
                            dataCategory.map((item, index) => (
                                item.offers.length ? 
                                <div key={index}>
                                    <CardBlock {...item}/>
                                </div>
                                : 
                                <div key={index} style={{height: 0}}></div>
                            ))
                        }
                    </div>
                :
                dataCategory.map((item, index) => (
                    item.offers.length ? 
                    <div key={index}>
                        <CardBlock {...item}/>
                    </div>
                    : 
                    <div key={index} style={{height: 0}}></div>
                ))
            }

{/* 
            {stateOffers.length ?
                !isFilter ?
                dataCategory.map((item, index) => (
                    item.offers.length ? 
                    <div key={index}>
                        <CardBlock {...item}/>
                    </div>
                    : 
                    <div key={index} style={{height: 0}}></div>
                ))
                :
                <CardBlock title='Подобрали для Вас:' img='' offers={stateOffers}/>
            :
                <div>
                    <h3>Извините, по выбранным параметрам ничего не найдено</h3>
                </div>    
        } */}
        </div>
    )
}

export default CardBlockWrapper;