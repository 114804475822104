import { Button } from 'antd';
import React, { useState } from 'react';
import { useCookies } from 'react-cookie';
import { v4 as uuidv4 } from 'uuid';
import { useAppDispatch } from '../../../app/hooks';
import { setCookieId } from '../../../app/AuthSlice';

const CookieWarningBanner: React.FC = () => {
  const [showBanner, setShowBanner] = useState(true);
  const [cookies, setCookie] = useCookies(['tj_uid', 'tj_notification']);
  const dispatch = useAppDispatch();

  const cookieNotification = cookies.tj_notification;
  const cookieUuid = cookies.tj_uid;
  let UTCDate = new Date(new Date().setFullYear(new Date().getFullYear() + 1));

  function handleAccept() {
    setShowBanner(false);
    setCookie('tj_notification', true, { path: '/', expires: UTCDate})
    //const newUserId = generateUniqueId();
    // Здесь можно записать состояние в локальное хранилище или куки
  }

  if (!cookieUuid) {
    const uuid = uuidv4();
    setCookie('tj_uid', uuid, { path: '/', expires: UTCDate, secure: true});
    dispatch(setCookieId(uuid))
  } else {
    dispatch(setCookieId(cookieUuid));
  }

  if (!showBanner || cookieNotification) {
    return null;
  }
  
  return (
    <div className="cookie-warning-banner">
        <div className='cookie-warning-banner_container'>
            <p>
                Мы используем cookie. 
                Это позволяет нам анализировать взаимодействие посетителей с сайтом и делать его лучше. 
                Продолжая пользоваться сайтом, вы соглашаетесь с использованием файлов cookie.
            </p>
            <Button type="primary" onClick={handleAccept}>Окей</Button>
        </div>
    </div>
  );
}

export default CookieWarningBanner;





