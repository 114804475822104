import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "./store";

interface authState {
    auth: boolean,
    user: {
        firebase_id: string,
        cookie_id: string,
    },
}

const initialState: authState = {
    auth: false,
    user: {
        firebase_id: '', 
        cookie_id: '',
    },
};



export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setAuth: (state, action: PayloadAction<boolean>) => {
            state.auth = action.payload;
        },
        setFirebaseUser: (state, action: PayloadAction<string>) => {
            state.user.firebase_id = action.payload;
        },
        setCookieId: (state, action: PayloadAction<string>) => {
            state.user.cookie_id = action.payload;
        },
    }
})

export const { setAuth, setFirebaseUser, setCookieId } = authSlice.actions;

export const selectAuthState = (state: RootState) => state.auth;

export default authSlice.reducer;