import React, { useState } from 'react';
import FilterPositionCity from './FilterPositionCity';
import FilterTags from './FilterTags';
import QuestionForm from '../main/questionForm/QuestionForm';

const Filters: React.FC = () => {
    const answersLS = localStorage.getItem('tj-question-form');
    const [showQuestionForm, setShowQuestionForm] = useState<boolean>(Boolean(!answersLS));

    return (
        <div className='header__filters'>
            <FilterPositionCity/>
            <FilterTags/>
            { showQuestionForm ?
            <div className='question-form-layout'>
                <QuestionForm setShowQuestionForm={setShowQuestionForm}/>
            </div>
            : <></>
            }
        </div>
    )
}

export default Filters;